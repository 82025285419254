import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-privacy',
  standalone: true,
  imports: [],
  templateUrl: './customer-privacy.component.html',
  styleUrl: './customer-privacy.component.scss'
})
export class CustomerPrivacyComponent {

}
