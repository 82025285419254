import { Routes } from '@angular/router';
import { CustomerTncComponent } from './components/customer-tnc/customer-tnc.component';
import { CustomerPrivacyComponent } from './components/customer-privacy/customer-privacy.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/app',
        pathMatch: 'full'
    },
    {
        path: 'app',
        loadChildren: () =>
            import('./modules/organization/organization.module').then(
                (m) => m.OrganizationModule
            ),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('./modules/error-pages/error-pages.module').then(
                (m) => m.ErrorPagesModule
            ),
    },
    {
        path: 'terms',
        component: CustomerTncComponent
    },
    {
        path: 'privacy',
        component: CustomerPrivacyComponent
    },
];
