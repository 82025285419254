<div class="container mt-5">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center mb-4">Privacy Policy</h1>

            <h2>1. Introduction</h2>
            <p>Welcome to <strong>clfx.in</strong>, operated by <strong>One N Quarter Technology PVT LTD</strong> ("we,"
                "our," or "us"). Protecting your privacy is our priority. This Privacy Policy outlines how we collect,
                use, disclose, and safeguard your information when you use our platform to book appointments. By
                accessing or using our services, you agree to the terms of this Privacy Policy.</p>

            <h2>2. Information We Collect</h2>
            <h3>2.1 Personal Information:</h3>
            <ul>
                <li>Name, contact details (email address, phone number), and address.</li>
                <li>Payment details for processing transactions.</li>
                <li>Booking history and preferences.</li>
            </ul>
            <h3>2.2 Non-Personal Information:</h3>
            <ul>
                <li>Device information, such as IP address, browser type, and operating system.</li>
                <li>Usage data, including interactions with our website or mobile app.</li>
                <li>Cookies and tracking technologies.</li>
            </ul>

            <h2>3. How We Use Customer Information</h2>
            <h3>3.1 To provide and manage our services, including:</h3>
            <ul>
                <li>Facilitating appointment bookings with vendors.</li>
                <li>Sending booking confirmations and reminders.</li>
                <li>Processing payments and refunds.</li>
            </ul>
            <h3>3.2 To enhance customer experience, including:</h3>
            <ul>
                <li>Personalizing your interactions with our platform.</li>
                <li>Providing customer support and responding to inquiries.</li>
                <li>Analyzing usage trends to improve our services.</li>
            </ul>
            <h3>3.3 To comply with legal obligations and ensure security, including:</h3>
            <ul>
                <li>Preventing fraud and unauthorized access.</li>
                <li>Complying with applicable laws and regulations.</li>
            </ul>

            <h2>4. Information Sharing and Disclosure</h2>
            <h3>4.1</h3>
            <p>We do not sell or rent your personal information to third parties.</p>
            <h3>4.2 We may share your information with:</h3>
            <ul>
                <li>Vendors to facilitate appointment bookings.</li>
                <li>Payment processors to complete transactions.</li>
                <li>Third-party service providers who assist with platform operations (e.g., hosting services).</li>
                <li>Regulatory authorities or legal entities when required by law.</li>
            </ul>

            <h2>5. Data Retention</h2>
            <p>We retain your information only as long as necessary to:</p>
            <ul>
                <li>Fulfill the purposes outlined in this Privacy Policy.</li>
                <li>Comply with legal and regulatory obligations.</li>
                <li>Resolve disputes and enforce our Terms and Conditions.</li>
            </ul>

            <h2>6. Data Security</h2>
            <h3>6.1</h3>
            <p>We implement robust measures to protect your information, including:</p>
            <ul>
                <li>Encryption of sensitive data during transmission.</li>
                <li>Regular monitoring and updates to security practices.</li>
            </ul>
            <h3>6.2</h3>
            <p>Customers are responsible for maintaining the confidentiality of their account credentials.</p>

            <h2>7. Customer Rights</h2>
            <h3>7.1</h3>
            <p>You have the right to:</p>
            <ul>
                <li>Access and review the personal information we hold about you.</li>
                <li>Request corrections to inaccurate or incomplete information.</li>
                <li>Request the deletion of your information, subject to legal or contractual obligations.</li>
            </ul>
            <h3>7.2</h3>
            <p>To exercise these rights, contact us at <a
                    href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a>.
            </p>

            <h2>8. Cookies and Tracking Technologies</h2>
            <p>We use cookies to:</p>
            <ul>
                <li>Enhance your experience on our platform.</li>
                <li>Track usage patterns and improve services.</li>
            </ul>
            <p>You can manage your cookie preferences through your browser settings.</p>

            <h2>9. Changes to this Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. Any changes will be effective upon posting, and
                significant updates will be communicated to you. Continued use of our platform signifies your acceptance
                of the revised Privacy Policy.</p>

            <h2>10. Contact Information</h2>
            <p>For questions or concerns about this Privacy Policy, please contact us at:</p>
            <ul class="contact-info">
                <li>Email: <a href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a></li>
                <li>Phone: +91 9924166945</li>
                <li>Address: One 'N Quarter Technology PVT LTD, 602 Supan Serenity, Near Kiran Motors, Sola, Ahmedabad,
                    380060</li>
            </ul>
        </div>
    </div>
</div>