import { ApplicationConfig, provideZoneChangeDetection, isDevMode, ErrorHandler } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { GlobolErrorHandlerService } from './shared/services/error/globol-error-handler.service';

export const appConfig: ApplicationConfig = {
  providers:
    [
      { provide: ErrorHandler, useClass: GlobolErrorHandlerService },
      provideHttpClient(), provideNoopAnimations(), provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
      })]
};
