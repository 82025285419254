<div class="container mt-5">
    <div class="text-center mb-4">
        <h1 class="fw-bold">Terms and Conditions</h1>
        <p class="text-muted">For Appointment Booking Website</p>
    </div>

    <div class="content">
        <h2>1. Acceptance of Terms</h2>
        <p>Welcome to <strong>clfx.in</strong>, operated by <strong>One N Quarter Technology PVT LTD</strong>. By using
            our website, you agree to these Terms and Conditions. If you do not accept these terms, please refrain from
            using our services.</p>

        <h2>2. Services Provided</h2>
        <p>We provide a platform to schedule and manage appointments with service providers. We act solely as an
            intermediary and are not responsible for the quality or outcome of services rendered by third-party
            providers.</p>

        <h2>3. User Eligibility</h2>
        <ul>
            <li>You must be at least 18 years old or have parental/guardian consent to use this website.</li>
            <li>By using our services, you confirm that all information you provide is accurate and up-to-date.</li>
        </ul>

        <h2>4. Account Registration</h2>
        <ul>
            <li><strong>4.1</strong> To book appointments, you may need to register an account. Safeguard your account
                credentials responsibly.</li>
            <li><strong>4.2</strong> Notify us immediately of any unauthorized access to your account.</li>
        </ul>

        <h2>5. Booking Terms</h2>
        <ul>
            <li><strong>5.1 Availability:</strong> Appointments are subject to the availability of service providers.
                Specific time slots are not guaranteed.</li>
            <li><strong>5.2 Accuracy of Information:</strong> Ensure that all booking details you provide are accurate
                and complete.</li>
            <li><strong>5.3 Confirmation:</strong> Bookings are confirmed only upon receiving a notification or email
                confirmation from us.</li>
        </ul>

        <h2>6. Cancellation and Rescheduling Policies</h2>
        <h3>6.1 Cancellation</h3>
        <ul>
            <li>Follow the policies set by the service provider for cancellations.</li>
            <li>Late cancellations may incur fees as determined by the provider.</li>
            <li>Refunds, if applicable, will be processed within 5-7 business days.</li>
        </ul>
        <h3>6.2 Rescheduling</h3>
        <ul>
            <li>Requests must comply with the service provider's policies.</li>
            <li>Rescheduling is subject to the provider’s availability.</li>
            <li>Multiple rescheduling attempts may result in additional charges.</li>
        </ul>

        <h2>7. Payments</h2>
        <ul>
            <li>Payments must be made in Indian Rupees (₹) and in accordance with the terms outlined during booking.
            </li>
            <li>A 3.5% convenience fee applies to all platform transactions.</li>
            <li>Refunds are processed per the service provider’s refund policy, with a 3.5% transfer fee deducted.</li>
        </ul>

        <h2>8. User Responsibilities</h2>
        <ul>
            <li>Use the platform only for lawful purposes.</li>
            <li>Ensure compliance with relevant laws in all interactions with service providers.</li>
            <li>Avoid misuse or interference with the website’s functionality.</li>
        </ul>

        <h2>9. Limitation of Liability</h2>
        <ul>
            <li>The quality or outcome of services from third-party providers.</li>
            <li>Delays, cancellations, or rescheduling issues.</li>
            <li>Losses arising from unauthorized use of your account.</li>
        </ul>

        <h2>10. Privacy Policy</h2>
        <p>Your personal information is handled in accordance with our Privacy Policy. By using this website, you
            consent to the practices described therein.</p>

        <h2>11. Intellectual Property</h2>
        <p>All website content, including trademarks and logos, is owned by or licensed to us. Unauthorized use or
            distribution is prohibited without prior written consent.</p>

        <h2>12. Modifications to Terms</h2>
        <p>We reserve the right to update these Terms and Conditions at any time. Changes will take effect upon posting.
        </p>

        <h2>13. Governing Law</h2>
        <p>These Terms and Conditions are governed by the laws of India and are subject to the exclusive jurisdiction of
            the courts in Ahmedabad, Gujarat.</p>

        <h2>14. Contact Information</h2>
        <ul class="contact-info">
            <li><strong>Email: </strong> <a href="mailto:ravijsoni@outlook.com">ravijsoni&#64;outlook.com</a></li>
            <li><strong>Phone:</strong> +91 9924166945</li>
            <li><strong>Address:</strong> One N Quarter Technology PVT LTD, 602 Supan Serenity, Near Kiran Motors, Sola,
                Ahmedabad, 380060</li>
        </ul>

        <h2>15. Termination</h2>
        <p>We reserve the right to suspend or terminate your access to the platform at our discretion, especially for
            violations of these Terms and Conditions.</p>
    </div>
</div>